import React, { useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import {
  ArrowLeftCircleIcon,
  ArrowUpTrayIcon,
  PencilSquareIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/outline";
import { useFormik } from "formik";
import { MuiTelInput } from "mui-tel-input";
import logoWhite from "../images/logo-white.png";
import Header from "../components/Header/Header";
import { Link, navigate } from "gatsby";
import toast from "react-hot-toast";
import axios from "axios";
import { Footer } from "../components/Footer/Footer";
import { useSelector } from "react-redux";
import { RootState } from "../reducers/rootReducer";
import StrongPassword from "../components/StrongPassword/StrongPassword";
import { Loader } from "../components/Loader/Loader";

const Account = ({ location }: any) => {
  const userName =
    typeof window !== "undefined" && localStorage.getItem("name");
  const user =
    typeof window !== "undefined" &&
    JSON.parse(localStorage.getItem("user") || "{}");
  const [userData, setUserData] = useState<any>([]);
  const [signed, setSigned] = useState(false);
  const [edit, setEdit] = useState(false);
  const [change, setChange] = useState(false);
  const [validPassword, setValidPassword] = useState<boolean>(false);
  const [application, setApplication] = useState(false);
  const [notification, setNotification] = useState(false);
  const [submitLoader, setSubmitLoader] = useState<boolean>(false);

  useEffect(() => {
    if (location.state !== null) {
      const { editStatus } = location.state;
      handleChange(editStatus);
    }
  }, [location]);

  const formikEdit = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: userData.firstName,
      lastName: userData.lastName,
      email: userData.email,
      mobilePhone: userData.mobilePhone,
    },
    validate: (values) => {
      const errors: {
        firstName?: string;
        lastName?: string;
        email?: string;
        mobilePhone?: string;
      } = {};

      if (!values.firstName) {
        errors.firstName = "Required";
      }

      if (!values.lastName) {
        errors.lastName = "Required";
      }

      if (!values.email) {
        errors.email = "Required";
      }

      if (
        values.email &&
        values.email &&
        !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(values.email)
      ) {
        errors.email = "Required";
      }

      if (!values.mobilePhone) {
        errors.mobilePhone = "Required";
      }

      return errors;
    },
    validateOnChange: false,
    onSubmit: async (values) => {
      setSubmitLoader(true);
      await updateUser(values);
      setSubmitLoader(false);
    },
  });

  const formikChange = useFormik({
    enableReinitialize: true,
    initialValues: {
      oldPassword: "",
      newPassword: "",
    },
    validate: (values) => {
      const errors: { oldPassword?: string; newPassword?: string } = {};

      if (!values.oldPassword) {
        errors.oldPassword = "Required";
      }

      if (!values.newPassword || !validPassword) {
        errors.newPassword = "Required";
      }

      return errors;
    },
    validateOnChange: false,
    onSubmit: async (values) => {
      setSubmitLoader(true);
      await changePassword(values);
      setSubmitLoader(false);
    },
  });

  const formikImage = useFormik({
    enableReinitialize: true,
    initialValues: {
      profile_url: "",
    },
    validate: (values) => {
      const errors: { profile_url?: any } = {};

      return errors;
    },
    validateOnChange: false,
    onSubmit: async (values) => {
      setSubmitLoader(true);
      let data = new FormData();
      if (formikImage.values.profile_url) {
        data.append("img", formikImage.values.profile_url, "imagen.png");
      }
      await updateUser(data);
      setSubmitLoader(false);
    },
  });

  useEffect(() => {
    getUser();
  }, []);

  const getUser = () => {
    let user = location?.state?.user?.profile;
    user.id = location?.state?.user?.id;
    user.status = location?.state?.user?.status;
    setUserData(user);
  };

  const updateUser = async (data: any) => {
    let token = localStorage.getItem("access_token");
    await axios({
      method: "patch",
      url: process.env.API_URL + "/api/admin-group/users/" + userData.id,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    })
      .then((response: any) => {
        if (response.data.status) {
          toast.success("Successfully updated");
          setTimeout(() => {
            return navigate("/students");
          }, 500);
        } else {
          toast.error("Something went wrong, try again");
        }
      })
      .catch((error: any) => {
        toast.error("Something went wrong, try again");
        console.log(error);
      });
  };

  const changePassword = async (data: any) => {
    let token = localStorage.getItem("access_token");
    await axios({
      method: "post",
      url:
        process.env.API_URL +
        "/api/admin-group/users/" +
        userData.id +
        "/changePassword",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: data,
    })
      .then(function (response) {
        if (response.data.status) {
          toast.success("Successfully updated");
          setTimeout(() => {
            return navigate("/students");
          }, 500);
        } else {
          toast.error("Something went wrong, try again");
        }
      })
      .catch(function (error) {
        toast.error("Something went wrong, try again");
        console.log(error);
      });
  };

  useEffect(() => {
    setEdit(true);
    setChange(false);
    setApplication(false);
    setNotification(false);
  }, []);

  const handlePassword = (data: boolean) => {
    setValidPassword(data);
  };

  const handleChange = (status: string) => {
    if (status === "edit") {
      setEdit(true);
      setChange(false);
      setApplication(false);
      setNotification(false);
    } else if (status === "change") {
      setEdit(false);
      setChange(true);
      setApplication(false);
      setNotification(false);
    } else if (status === "application") {
      setEdit(false);
      setChange(false);
      setApplication(true);
      setNotification(false);
    } else {
      setEdit(false);
      setChange(false);
      setApplication(false);
      setNotification(true);
    }
  };

  const { items }: any = useSelector((state: RootState) => state.loginSlice);

  useEffect(() => {
    if (items.status) {
      setSigned(true);
    } else {
      navigate("/");
    }
  }, [items]);

  return (
    <Layout signed={signed}>
      <div className="bg-slate-50">
        {/* <Header isSignIn={signed} /> */}
        {/* Title tab */}
        <section className="container px-[15px] mx-auto">
          <div className="mt-10  mb-10 flex lg:items-center justify-between flex-col lg:flex-row">
            <div className="mb-4 md:mb-0">
              <div className="flex items-center mb-2">
                <Link to="/students" className="flex items-center">
                  <ArrowLeftCircleIcon className="h-6 w-6" />
                  <span className="ff-cg--medium ml-2">BACK TO STUDENTS</span>
                </Link>
              </div>
              <h2 className="text-[30px] lg:text-[60px] ff-cg--semibold leading-none">
                Edit Student
              </h2>
            </div>
            <div className="flex items-center overflow-x-auto">
              <div
                className="flex items-center flex-col cursor-pointer"
                onClick={() => handleChange("edit")}
              >
                <p
                  className={`pb-3 px-10 whitespace-nowrap ${
                    edit ? "ff-cg--semibold " : ""
                  }`}
                >
                  Personal Information
                </p>
                <span
                  className={`border-b border-solid w-full ${
                    edit ? "border-[#da1a32] border-2" : ""
                  }`}
                ></span>
              </div>
              <div
                className="flex items-center flex-col cursor-pointer"
                onClick={() => handleChange("change")}
              >
                <p
                  className={`pb-3 px-10 whitespace-nowrap ${
                    change ? "ff-cg--semibold " : ""
                  }`}
                >
                  Change Picture
                </p>
                <span
                  className={`border-b border-solid w-full ${
                    change ? "border-[#da1a32] border-2" : ""
                  }`}
                ></span>
              </div>
              <div
                className="flex items-center flex-col cursor-pointer"
                onClick={() => handleChange("application")}
              >
                <p
                  className={`pb-3 px-10 whitespace-nowrap ${
                    application ? "ff-cg--semibold " : ""
                  }`}
                >
                  Change Password
                </p>
                <span
                  className={`border-b border-solid w-full ${
                    application ? "border-[#da1a32] border-2" : ""
                  }`}
                ></span>
              </div>
              <div
                className="flex items-center flex-col cursor-pointer"
                onClick={() => handleChange("notification")}
              >
                <p
                  className={`pb-3 px-10 whitespace-nowrap ${
                    notification ? "ff-cg--semibold " : ""
                  }`}
                >
                  Enrollments
                </p>
                <span
                  className={`border-b border-solid w-full ${
                    notification ? "border-[#da1a32] border-2" : ""
                  }`}
                ></span>
              </div>
            </div>
          </div>
        </section>

        {/* Edit Profile */}
        {edit && (
          <section className="container px-[15px] mx-auto md:mb-20 mb-10">
            <form
              onSubmit={formikEdit.handleSubmit}
              className="rounded-md bg-white shadow-lg p-[15px] md:p-[30px] pb-10 md:pb-16"
            >
              <h3 className="text-[20px] ff-cg--semibold lg:text-[30px] mb-6">
                Personal Information
              </h3>
              <div className="grid gap-4 lg:gap-10 md:grid-cols-12 mb-10">
                <div className="md:col-span-6 lg:col-span-6">
                  <div className="flex items-center justify-between">
                    <p className="ff-cg--semibold">First Name</p>
                  </div>
                  <input
                    className={
                      "placeholder:text-slate-400 focus:outline-none w-full bg-slate-50 p-4 mt-2 rounded-2xl ff-cg--medium" +
                      (formikEdit.errors.firstName == "Required"
                        ? " border border-red-500"
                        : "")
                    }
                    name="firstName"
                    type="text"
                    onChange={formikEdit.handleChange}
                    value={formikEdit.values.firstName}
                  />
                </div>
                <div className="md:col-span-6 lg:col-span-6">
                  <div className="flex items-center justify-between">
                    <p className="ff-cg--semibold">Last Name</p>
                  </div>
                  <input
                    className={
                      "placeholder:text-slate-400 focus:outline-none w-full bg-slate-50 p-4 mt-2 rounded-2xl ff-cg--medium" +
                      (formikEdit.errors.lastName == "Required"
                        ? " border border-red-500"
                        : "")
                    }
                    name="lastName"
                    type="text"
                    onChange={formikEdit.handleChange}
                    value={formikEdit.values.lastName}
                  />
                </div>
                <div className="md:col-span-6 lg:col-span-6">
                  <div className="flex items-center justify-between">
                    <p className="ff-cg--semibold">Email Address</p>
                  </div>
                  <input
                    className={
                      "placeholder:text-slate-400 focus:outline-none w-full bg-slate-50 p-4 mt-2 rounded-2xl ff-cg--medium" +
                      (formikEdit.errors.email == "Required"
                        ? " border border-red-500"
                        : "")
                    }
                    name="email"
                    type="email"
                    onChange={formikEdit.handleChange}
                    value={formikEdit.values.email}
                  />
                </div>
                <div className="md:col-span-6 lg:col-span-6">
                  <div className="flex items-center justify-between">
                    <p className="ff-cg--semibold">Phone Number</p>
                  </div>
                  <MuiTelInput
                    className={
                      "placeholder:text-slate-400 focus:outline-none w-full bg-slate-50 p-4 mt-2 rounded-2xl ff-cg--medium" +
                      (formikEdit.errors.mobilePhone == "Required"
                        ? " !border-solid !border !border-red-500"
                        : "")
                    }
                    name="mobilePhone"
                    defaultCountry={"US"}
                    onChange={(value) =>
                      formikEdit.setFieldValue("mobilePhone", value)
                    }
                    value={formikEdit.values.mobilePhone}
                  />
                </div>
              </div>
              <div className="md:flex items-center justify-center gap-4 lg:gap-10">
                <button
                  onClick={() => navigate("/")}
                  className="w-full md:w-[200px] flex items-center justify-center border border-[#222222] py-[14px] px-[16px] rounded-2xl mb-4 md:mb-0"
                >
                  <span className="ff-cg--semibold">Return</span>
                </button>
                <button
                  type="submit"
                  className={
                    "flex items-center justify-center bg-[#fdbf38] h-[52px] px-[16px] rounded-2xl w-full md:w-[200px] " +
                    (submitLoader ? "!bg-[#da1a32] justify-between" : "")
                  }
                >
                  <span
                    className={
                      "ff-cg--semibold " + (submitLoader ? "text-white" : "")
                    }
                  >
                    Save Change
                  </span>
                  <Loader
                    open={submitLoader}
                    dimension={"8"}
                    min={"8"}
                    wrapClass={""}
                  />
                </button>
              </div>
            </form>
          </section>
        )}

        {/* Change Password */}
        {change && (
          <section className="container px-[15px] mx-auto md:mb-20 mb-10">
            <form
              onSubmit={formikImage.handleSubmit}
              className="rounded-md bg-white shadow-lg p-[15px] md:p-[30px] pb-10 md:pb-16"
            >
              <h3 className="text-center text-[20px] ff-cg--semibold  lg:text-[30px] mb-6">
                Change Picture
              </h3>
              <div className="grid gap-4 lg:gap-10 md:grid-cols-12 mb-10">
                <div className="col-span-12 text-center">
                  <label form="profile_url">
                    <img
                      className="w-[220px] h-[220px] m-auto rounded-full"
                      src={userData.profileUrl}
                      alt=""
                    />
                    <p className="pt-2 text-[18px] ff-cg--semibold">
                      {userData.firstName} {userData.lastName}
                    </p>
                    <input
                      id="profile_url"
                      name="profile_url"
                      type="file"
                      className="hidden"
                      onChange={(event: any) =>
                        formikImage.setFieldValue(
                          "profile_url",
                          event.target.files[0]
                        )
                      }
                      value={""}
                    />
                  </label>
                </div>
              </div>
              <div className="md:flex items-center justify-center gap-4 lg:gap-10">
                <button
                  onClick={() => navigate("/students")}
                  className="w-full md:w-[200px] flex items-center justify-center border border-[#222222] py-[14px] px-[16px] rounded-2xl mb-4 md:mb-0"
                >
                  <span className="ff-cg--semibold">Return</span>
                </button>
                <button
                  type="submit"
                  className={
                    "flex items-center justify-center bg-[#fdbf38] h-[52px] px-[16px] rounded-2xl w-full md:w-[200px] " +
                    (submitLoader ? "!bg-[#da1a32] justify-between" : "")
                  }
                >
                  {!submitLoader && <ArrowUpTrayIcon className="h-6 w-6" />}
                  <span
                    className={
                      "ff-cg--semibold whitespace-nowrap ml-2" +
                      (submitLoader ? "text-white" : "")
                    }
                  >
                    Upload New Picture
                  </span>
                  <Loader
                    open={submitLoader}
                    dimension={"8"}
                    min={"8"}
                    wrapClass={""}
                  />
                </button>
              </div>
            </form>
          </section>
        )}

        {/* Your Applications */}
        {application && (
          <section className="container px-[15px] mx-auto md:mb-20 mb-10">
            <form
              onSubmit={formikChange.handleSubmit}
              className="rounded-md bg-white shadow-lg p-[15px] md:p-[30px] pb-10 md:pb-16"
            >
              <h3 className="text-[20px] ff-cg--semibold lg:text-[30px] mb-6">
                Change Password
              </h3>
              <div className="grid gap-4 lg:gap-10 md:grid-cols-12 mb-10">
                <div className="md:col-span-6 lg:col-span-6">
                  <div className="flex items-center justify-between">
                    <p className="ff-cg--semibold">Old Password</p>
                  </div>
                  <input
                    className={
                      "placeholder:text-slate-400 focus:outline-none w-full bg-slate-50 p-4 mt-2 rounded-2xl ff-cg--medium" +
                      (formikChange.errors.oldPassword == "Required"
                        ? " border border-red-500"
                        : "")
                    }
                    name="oldPassword"
                    type="password"
                    onChange={formikChange.handleChange}
                    value={formikChange.values.oldPassword}
                  />
                  {formikChange.errors.oldPassword && (
                    <p className="ff-cg--semibold text-red-500 text-[14px] mt-2 flex items-center">
                      <ExclamationTriangleIcon className="w-4 h-4 mr-1" /> Old
                      Password is required
                    </p>
                  )}
                </div>
                <div className="md:col-span-6 lg:col-span-6">
                  <div className="flex items-center justify-between">
                    <p className="ff-cg--semibold">New Password</p>
                  </div>
                  <input
                    className={
                      "placeholder:text-slate-400 focus:outline-none w-full bg-slate-50 p-4 mt-2 rounded-2xl ff-cg--medium" +
                      (formikChange.errors.newPassword == "Required"
                        ? " border border-red-500"
                        : "")
                    }
                    name="newPassword"
                    type="password"
                    onChange={formikChange.handleChange}
                    value={formikChange.values.newPassword}
                  />
                  <StrongPassword
                    value={formikChange.values.newPassword}
                    validate={
                      formikChange.errors.newPassword == "Required" &&
                      formikChange.values.newPassword === ""
                    }
                    callback={(event: boolean) => handlePassword(event)}
                  />
                </div>
              </div>
              <div className="md:flex items-center justify-center gap-4 lg:gap-10">
                <button
                  onClick={() => navigate("/students")}
                  className="w-full md:w-[200px] flex items-center justify-center border border-[#222222] py-[14px] px-[16px] rounded-2xl mb-4 md:mb-0"
                >
                  <span className="ff-cg--semibold">Return</span>
                </button>
                <button
                  type="submit"
                  className={
                    "flex items-center justify-center bg-[#fdbf38] h-[52px] px-[16px] rounded-2xl w-full md:w-[200px] " +
                    (submitLoader ? "!bg-[#da1a32] justify-between" : "")
                  }
                >
                  <span
                    className={
                      "ff-cg--semibold " + (submitLoader ? "text-white" : "")
                    }
                  >
                    Save Change
                  </span>
                  <Loader
                    open={submitLoader}
                    dimension={"8"}
                    min={"8"}
                    wrapClass={""}
                  />
                </button>
              </div>
            </form>
          </section>
        )}

        {/* Manage Notifications */}
        {notification && (
          <section className="container px-[15px] mx-auto md:mb-20 mb-10">
            <div className="rounded-md bg-white shadow-lg">
              <h3 className="text-[20px] ff-cg--semibold lg:text-[30px] border-b border-solid p-[15px] md:p-[30px]">
                Current Enrollments
              </h3>
              {/* <div className="flex items-center justify-between border-b boder-solid p-[15px] md:p-[30px] md:py-[20px]">
                <p className="underline">Cybersecurity Bootcamp</p>
                <button className="ml-auto w-full lg:w-fit flex items-center justify-between border border-solid border-black py-[14px] px-[16px] rounded-2xl">
                  <PencilSquareIcon className="h-6 w-6" />
                  <span className="ff-cg--semibold ml-[20px] whitespace-nowrap">Remove Seat</span>
                </button>
              </div>
              <div className="flex items-center justify-between border-b boder-solid p-[15px] md:p-[30px] md:py-[20px]">
                <p className="underline">Cybersecurity Bootcamp</p>
                <button className="ml-auto w-full lg:w-fit flex items-center justify-between border border-solid border-black py-[14px] px-[16px] rounded-2xl">
                  <PencilSquareIcon className="h-6 w-6" />
                  <span className="ff-cg--semibold ml-[20px] whitespace-nowrap">Remove Seat</span>
                </button>
              </div> */}
              <div className="p-8">
                <p className="font-bold text-3xl text-center w-full">
                  There is no info for this section
                </p>
              </div>
            </div>
          </section>
        )}

        {/* footer */}
        <Footer />
      </div>
    </Layout>
  );
};

export default Account;
export { Head } from "../components/Layout/Head";
